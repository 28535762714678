import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { SnackbarService } from './services/snackbar.service';
import { SnackBarMesssage } from '../../models/snackbar-message.interface';
import { MessageTypes } from '../../models/message-types.enum';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent {

  messageTypes = MessageTypes;

  showMessage$: Observable<Boolean> | undefined;

  messageSettings$: Observable<SnackBarMesssage> | undefined;

  constructor(private _snackbarService: SnackbarService) {}

  ngOnInit(): void {
    this.showMessage$ = this._snackbarService.showMessage;
    this.messageSettings$ = this._snackbarService.messageSettings;
  }
}
