import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MessageTypes } from 'src/app/shared/models/message-types.enum';
import { SnackBarMesssage } from 'src/app/shared/models/snackbar-message.interface';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  private messageSuccess: SnackBarMesssage = {
    type: MessageTypes.SUCCESS,
    message: 'Proceso exitoso!'
  }

  /** Subject to listen changes in message type */
  private messageSettingsChange$ = new BehaviorSubject<SnackBarMesssage>(this.messageSuccess);

  /** Subject to listen changes in show message flag */
  private showMessageChange$ = new BehaviorSubject<Boolean>(false);

  /**
  * get message settings
  * @returns boolean observable
  */
  get messageSettings(): Observable<SnackBarMesssage> {
    return this.messageSettingsChange$.asObservable();
  }

  /**
  * get show message flag value
  * @returns boolean observable
  */
  get showMessage(): Observable<Boolean> {
    return this.showMessageChange$.asObservable();
  }

  /**
  * set message settings
  * @param {SnackBarMesssage} message
  */
  setMessageSettings(message: SnackBarMesssage): void {
    this.messageSettingsChange$.next(message);
    this.showSnackbar();
  }

  /**
  * set message flag value
  */
  showSnackbar(): void {
    this.showMessageChange$.next(true);
    setTimeout(() => {
      this.showMessageChange$.next(false);
    }, 5000);
  }
}
