import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { LoadingService } from './services/loading.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {

  showLoading$: Observable<Boolean> | undefined;

  constructor(private _loadingService: LoadingService) {}

  ngOnInit(): void {
    this.showLoading$ = this._loadingService.showLoadingValue;
  }
}
