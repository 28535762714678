import { Injectable } from '@angular/core';
import { Auth, authState } from '@angular/fire/auth';
import { CanActivate, Router } from '@angular/router';
import { Observable, switchMap, take, tap } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

  constructor(private auth: Auth, private router: Router, private authService: AuthService) {}

  canActivate(): Observable<boolean> {
    return authState(this.auth).pipe(
      take(1),
        switchMap(async (authState) => {
          if (!authState) {
              this.router.navigate(['/auth/login'])
              return false
          }
          const token = await authState.getIdTokenResult();
          if (token.claims['user_id']) {
              const privileges = await this.authService.haveAdminPrivileges(this.authService.getUserInfo()?.uid || '');
              await privileges.toPromise().then((admin: any) => {
                if (!admin || !admin.data) {
                  this.router.navigate(['/dashboard'])
                  return false
                }
              }).catch(() => {
                this.router.navigate(['/dashboard'])
                return false
              })
              return true
          } else {
            this.router.navigate(['/dashboard'])
            return false
          }
        }),
    )
  }
  
}
