import { Component } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'JOC Cards';

  public installPrompt!: any;

  constructor(private swUpdate: SwUpdate) {
    if (this.swUpdate.available) {
      this.swUpdate.available.subscribe(() => {
        if (confirm('Una nueva versión de la aplicación esta disponible. Recargar...'))
        window.location.reload();
      })
    }
  }

  getInstallPrompt(): void {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      this.installPrompt = e;
    });
  }

  askUserToInstallApp() {
    this.installPrompt.prompt();
  }
}
