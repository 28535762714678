import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { 
  Auth,
  confirmPasswordReset, 
  getAuth, 
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  verifyPasswordResetCode
} from '@angular/fire/auth';
import { LoginData } from 'src/app/shared/models/login-data.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private auth: Auth, private http: HttpClient) {}

  login({ email, password }: LoginData) {
    return signInWithEmailAndPassword(this.auth, email, password);
  }

  logout() {
    return signOut(this.auth);
  }

  recoveryPassword({ email }: LoginData) {
    return sendPasswordResetEmail(
      this.auth, 
      email, 
      { url: `${environment.url_site}auth?email=${email}` }
    )
  }

  verifyPasswordResetCode(code: string) {
    return verifyPasswordResetCode(this.auth, code);
  }

  confirmPasswordReset(code: string, newPassword: string) {
    return confirmPasswordReset(this.auth, code, newPassword);
  }

  async haveAdminPrivileges(userId: string) {
    return this.http.get(environment.url_api + 'is-admin/' + userId);
  }

  getUserToken() {
    return getAuth().currentUser?.getIdToken();
  }

  getUserInfo() {
    return getAuth().currentUser;
  }
}
