import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  /** Subject to listen changes in show loading flag */
  private showLoadingChange$ = new BehaviorSubject<Boolean>(false);

  /**
  * get show loading flag value
  * @returns boolean observable
  */
  get showLoadingValue(): Observable<Boolean> {
    return this.showLoadingChange$.asObservable();
  }

  /**
  * set show loading flag value
  * @param {boolean} show
  */
  setShowLoadingValue(show: boolean): void {
    this.showLoadingChange$.next(show);
  }
  
}
