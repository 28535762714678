export const environment = {
  production: false,
  firebase: {
    projectId: 'instagames-app',
    appId: '1:677174503052:web:fd40ecc1a9ac5c9afafda9',
    storageBucket: 'instagames-app.appspot.com',
    locationId: 'southamerica-east1',
    apiKey: 'AIzaSyBaB93wX_-IgzsL7_OMgbLnnPZk-4mpTAI',
    authDomain: 'instagames-app.firebaseapp.com',
    messagingSenderId: '677174503052',
  },
  url_site: 'https://joccards-dev.calia.com.co/',
  url_api: 'https://api-joccards-prod.calia.com.co/',
  title: 'Production Environment',
  nameCollection: 'contracts'
};
