<figure 
    class="notification" *ngIf="showMessage$ | async" 
    [ngClass]="{'notification--error': (messageSettings$ | async)?.type === messageTypes.ERROR}">
        <div class="notification-body">
            <i class="fa notification-body__icon"
                [ngClass]="{
                    'fa-check-circle-o': (messageSettings$ | async)?.type === messageTypes.SUCCESS,
                    'fa-times-circle-o': (messageSettings$ | async)?.type === messageTypes.ERROR
                }">
            </i>
            <span class="notification-body__text">{{ (messageSettings$ | async)?.message }}</span>
        </div>
        <div 
            class="notification__progress"
            [ngClass]="{'notification__progress--error': (messageSettings$ | async)?.type === messageTypes.ERROR}">
        </div>
</figure>